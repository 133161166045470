export class PermissionUtil {
    /**
      * Make permission array
      * @param permissions {Array<object>} : permission for module access
      */
    public static setPermission(permissions: Array<Object>) {
        const permissionList = [];
        (permissions || []).forEach((permission: any) => {
            const name = permission.per_name.trim().split(' ').join('_').toUpperCase();
            if (permission.admnrl_per_edit) {
                permissionList.push(`${name}_EDIT`);
            }
            if (permission.admnrl_per_view) {
                permissionList.push(`${name}_VIEW`);
            }
            if (permission.admnrl_per_delete) {
                permissionList.push(`${name}_DELETE`);
            }
            if (permission.admnrl_per_create) {
                permissionList.push(`${name}_CREATE`);
            }
            if (permission.admnrl_per_approve) {
                permissionList.push(`${name}_APPROVE`);
            }
            if (permission.admnrl_per_export) {
                permissionList.push(`${name}_EXPORT`);
            }
            if (permission.admnrl_per_active) {
                permissionList.push(`${name}_ACTIVE`);
            }
        });        
        return permissionList;
    }
}