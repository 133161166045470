/**
 * Http Api Method config enum for api call
 */
export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
  UPLOAD = 'upload',
  UPLOAD_PATCH = 'upload-patch',
  UPLOAD_PUT = 'upload-put',
}