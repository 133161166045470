// ANGULAR DEPENDENCY
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import "@angular/common/locales/global/en-IN";

// EXTERNAL MODUELS
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuillModule } from 'ngx-quill';
import { NgxPermissionsModule } from 'ngx-permissions';
import { Attributes, IntersectionObserverHooks, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';

// INTERNAL MODULES
import { ConfirmModalModule } from './shared/base/modal/confirm-modal/confirm-modal.module';
import { ToastrModule } from './shared/base/toastr/toastr.module';
import { UiModule } from './modules/ui.module';
import { UiRoutingModule } from './modules/ui-routing.module';

// COMPONENTS
import { AppComponent } from './app.component';

// INTERCEPTORS
import { AuthInterceptor } from './shared/_core/interceptor/auth.interceptor';

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  onAttributeChange(newAttributes: Attributes) {
  }
  loadImage({ imagePath }: Attributes): Promise<string> {
    return fetch(imagePath)
      .then(res => res.blob())
      .then(blob => URL.createObjectURL(blob));
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    /*** Angular Modules***/
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    /*** EXTERNAL MODULES ***/
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgSelectModule,
    ClipboardModule,
    QuillModule.forRoot(),
    NgbModule,
    NgxPermissionsModule.forRoot(),
    /*** INTERNAL MODULES ***/
    UiModule,
    UiRoutingModule,
    ConfirmModalModule,
    ToastrModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "en-IN" },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
