export const COMMON_CONSTANTS = {
  TOOLTIP: {
    CHANGE_IMAGE: 'Change Image',
    REMOVE_IMAGE: 'Remove Image',
    REMOVE: 'Delete',
    REMOVE_ATTACHMENT: 'Remove Attachement',
    RESET: 'reset'
  },
  HINT: {
    SELECT_SEARCH: 'Start Typing...',
    NO_RECORD_FOUND: 'No Records Found.'
  },
  LABEL: {
    RESET: 'reset'
  },
  TITLE: {
    CHOOSE_IMAGE: 'Choose Image',
    CHOOSE_AUDIO: 'Choose Audio'
  },
  BUTTON: {
    ADD: 'Add',
    VIEW: 'View',
    EDIT: 'Edit',
    DELETE: 'Delete',
    SUBMIT: 'Submit',
    SAVE: 'Save',
    CLOSE: 'Close',
    CANCEL: 'Cancel',
    NEXT: 'Next',
    PREV: 'Previous',
    CONTINUE: 'Continue',
    FILTER: 'Filter',
    DISABLE: 'Disable',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    UPLOAD: 'Upload',
    SETTING: 'Setting',
    REFRESH: 'Refresh',
    QR: 'Qr Code',
    URL: 'Url',
    STATISTICS: 'Statistics',
    CLONE: 'Clone',
    EDITOR: 'Editor',
    SELECT_ALL: 'Select All',
    DESELECT_ALL: 'Deselect All',
    MOVE: 'Move',
    DOWNLOAD: 'Download',
    SEARCH: 'Search',
    YES: 'Yes',
    IMPORT: 'Import',
    READ: 'Read All',
    CLEAR: 'Clear All',
  },
  CONFIRM: {
    'CONFIRMATION': 'Confirmation',
    'DELETE': 'Delete',
    'DELETE_SELECTED': 'Delete Selected',
    'ERROR': 'Error',
    'INFO': 'Infomation',
    'ALERT': 'Alert',
    'ENABLE': 'Enable',
    'DISABLE': 'Disable',
    'APPROVE': 'Approve',
    'REJECT': 'Reject',
    'STATUS': 'Status'
  },
  MESSAGE: {
    'DELETE': 'Are you sure that you want to delete this record?',
    'DELETE_ALL': 'Are you sure that you want to delete all records?',
    'DELETE_SELECTED': 'Are you sure that you want to delete selected records?',
    'ENABLE': 'Are you sure that you want to enable this record(s)?',
    'DISABLE': 'Are you sure that you want to disable this record(s)?',
    'APPROVE': 'Are you sure that you want to approve this record(s)?',
    'REJECT': 'Are you sure that you want to reject this record(s)?',
    'INFO': 'Infomation',
  },
  TABLE: {
    'ACTION': 'Action',
    'MANAGE': 'Manage',
    'ACTION_BUTTON': 'Actions',
    'ACTION_SWITCH': 'Actions',
    'BULK': 'Bulk Actions',
    'STATUS': 'Status',
    'EXPORT_FILE': 'file',
    'PROGRESS': 'Progress',
    'PAGGING_TEMPLATE': 'Showing {first} - {last} of {totalRecords}',
  },
  TOASTER: {
    ADD: 'has been added successfully',
    DELETE: 'has been deleted successfully',
    UPDATE: 'has been updated successfully',
    UPDATE_STATUS: 'Status has been updated successfully',
    SOMETHING_WENT_WRONG: 'Something went wrong. Please contact to your Administrator'
  }
}

