import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminStorageService {
  admin: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  /**
   * Set business Data
   * @param business : Api common payload object
   * @returns void
   */
  public setAdmin(admin?: any): any {
    this.admin.next(admin);
  }

  /**
   * Get business Data
   * @returns Observable<business>
   */
  public getAdmin(observable: boolean = false): Observable<any> | any {
    if (!observable) return this.admin.getValue();
    return this.admin.asObservable();
  }
}
