// Angular
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

// Internal Modules
import { ThemeModeService } from 'src/app/_metronic/partials/layout/theme-mode-switcher/theme-mode.service';

// Services
import { TranslationService } from 'src/app/i18n';

// Constants language list
import { locale as enLang } from 'src/app/i18n/vocabs/en';
import { locale as chLang } from 'src/app/i18n/vocabs/ch';
import { locale as esLang } from 'src/app/i18n/vocabs/es';
import { locale as jpLang } from 'src/app/i18n/vocabs/jp';
import { locale as deLang } from 'src/app/i18n/vocabs/de';
import { locale as frLang } from 'src/app/i18n/vocabs/fr';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.modeService.init();
  }
}