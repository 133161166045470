/**
 * Default Constant enum for default features
 */
export enum DefaultConstant {
  TIMEZONE = 'Asia/Kolkata',
  SYSTEM_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss',
  LOCAL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss',
  SYSTEM_DATE_FORMAT = 'YYYY-MM-DD',
  LOCAL_DATE_FORMAT = 'DD/MM/YYYY',
  TIME_12_FORMAT = 'hh:mm:ss A',
  TIME_24_FORMAT = 'HH:mm:ss',
  USER_IMAGE = 'default.jpg',
  DEFAULT_FILE_RESPONSE = 'data.fullPath',
  LOCAL_DATETIME_12_FORMAT = 'DD/MM/YYYY hh:mm:ss a'
}