<div class="modal-header ps-4 pe-3 py-4">
  <h4 class="modal-title">{{ options.title | translate }}</h4>
  <button type="button" class="btn-close modal-close-btn me-0" aria-label="Close button" aria-describedby="modal-title"
    (click)="close()"></button>
</div>
<div class="modal-body">
  <p class="mb-0 min-h-50px" *ngIf="options?.message"><span *ngIf="options.icon"><i class="{{options.icon}} me-3"></i></span><strong>{{(
      options.message || 'ERROR.GLOBAL_ERROR' ) | translate}}</strong></p>
  <p class="mb-0 min-h-50px" *ngIf="options?.messageHTML" [innerHTML]="(options.messageHTML | translate) | safe: 'html'"></p>
  <p class="mb-0 min-h-50px" *ngIf="options?.description" [innerHTML]="(options.description | translate) | safe: 'html'"></p>
</div>
<div class="modal-footer py-2 px-3">
  <button *ngIf="options.button1" type="button" class="{{ options.button1Class || 'btn btn-primary' }} py-2"
    (click)="button1Click()"><i *ngIf="options.button1Icon" class="{{options.button1Icon}}"></i> {{
    (options.button1Text || 'COMMON.BUTTON.OK' ) | translate }}</button>
  <button *ngIf="options.button2" type="button" class="{{ options.button2Class || 'btn btn-sm btn-light' }} py-2"
    (click)="button2Click()"><i *ngIf="options.button2Icon" class="{{options.button2Icon}}"></i>
    {{ (options.button2Text || 'COMMON.BUTTON.CLOSE') | translate }}
  </button>
</div>