export * from './http-method.enum';

/**
 * Http Api Method config enum for api call
 */

export enum ApiModule {
  PAGE_API = 'page/admin',
  API = 'api/v1',
  MASTER_API = 'api/v1/master',
  THIRD_PARTY = 'third_party',
  GOOGLE_API = 'google-api',
  IPFY_API = 'ip-fy-api',
  ORDER = 'api/v1/order',
}

/**
 * Http Api Method config enum for api call
 */
export enum ApiMethod {
  ADD = 'add',
  UPDATE = 'update',
  ALL = 'all',
  GET = 'get',
  PAGGING = 'pagging',
  EXPORT = 'export',
  STATUS = 'status',
  DELETE = 'delete',
  REORDER = 'reorder',
  LOOKUP = 'lookup'
}