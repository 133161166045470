export const ERROR_CONSTANTS = {
  VALIDATION: {
    REQUIRED: ' is required.',
    MAX: ' maximum number must be {{max}}.',
    MIN: ' minimum number must be {{min}}.',
    MIN_LENGTH: ' should have at least {{minLength}} characters.',
    MAX_LENGTH: ' should have maximum {{maxLength}} characters.',
    PATTERN: ' is invalid format.',
    MIN_SELECTION: 'Atleast {{minSelection}} option(s) must be checked',
    MAX_SELECTION: 'Maximum {{maxSelection}} option(s) can be checked',
    REQ_SELECTION: ' is required.',
    CONFIRM_PASSWORD: ' does not match.',
    GREATER_THAN_DATE: 'From Date Must be less than To Date',
    LESS_THAN_DATE: 'To Date Must be greater than From Date',
    GREATER_THAN_TIME: 'To Time Must be greater than From Time',
    LESS_THAN_TIME: 'From Time Must be less than To Time',
    NO_PAST_DATE_TIME: 'Date & time must be greater than Past date & time',
    GREATER_THAN_NUMBER: 'To Number Must be greater than From Number',
    LESS_THAN_NUMBER: 'From Number Must be less than To Number',
    MULTI_EMAIL: 'Please enter valid email address',
    ALLOWED_EXTENSIONS: 'Please upload supported files: {{allowedExtensions}}.',
    EMPTY_EXTENSTION: 'Plese select atleast one extension',
    ALLOWED_DIMENTIONS: 'Please upload with an allowed file dimentions. Allowed file dimentions: {{allowedDimentions}}.',
    MAX_FILE_SIZE: 'File is too large. file size must not be larger than {{maxFileSize}} mb.',
    TEMPLETE_MAX_FILE_SIZE: 'File is too large. file size must not be larger than {{maxFileSize}} {{fileType}}.',
    MIN_FILE_SIZE: 'File is too small. file size must not be smaller than {{minFileSize}} mb.',
    MAX_FILE_ALLOWED: 'Maximum {{maxFileAllowed}} files allowed for upload.',
    MIN_FILE_ALLOWED: 'Minimum {{minFileAllowed}} files required for upload.',
    FILE_UPLOAD: 'Error in file upload.',
    EXPAND_MAX_ERROR: 'Q & A can"t be greter than maximum Q & A',
    POLL_MAX_ERROR: "Poll can't be greter than maximum expand",
    FAQS_MAX_ERROR: "Faqs can't be greter than maximum faqs",
  },
  COMMON: {
    NO_RECORD_FOUND: 'No Record Found(s)',
    FILE_UPLOAD: 'Error in file upload.',
    API_CALL: 'Oops! Something went wrong. Please contact your administrator.'
  }
};