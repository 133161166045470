// Angular Dependency
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Internal Modules
import { UiRoutingModule } from './ui-routing.module';

@NgModule({
  declarations: [],
  imports: [
    /*** Angular Modules***/
    CommonModule,
    /*** Routing Modules ***/
    UiRoutingModule,
    /*** Internal Modules ***/
  ],
  exports: [
    UiRoutingModule
  ]
})
export class UiModule { }
