import { Injectable } from '@angular/core';
import { ObjectUtils } from '../utils';
import moment from 'moment-timezone';
import { DefaultConstant } from 'src/app/shared/constants/enums/core/default.enum';
import { DateUtil } from 'src/app/shared/_core/utils/date';

@Injectable({ providedIn: 'root' })
export class FilterService {

    filter(value: any[], fields: any[], filterValue: any, filterMatchMode: string, filterLocale?: string) {
        let filteredItems: any[] = [];

        if (value) {
            for (let item of value) {
                for (let field of fields) {
                    let fieldValue = ObjectUtils.resolveFieldData(item, field);

                    if (this.filters[filterMatchMode](fieldValue, filterValue, filterLocale)) {
                        filteredItems.push(item);
                        break;
                    }
                }
            }
        }
        return filteredItems;
    }

    public filters = {
        getVaule: (value) => {
            let search = value.toString()
            if (typeof value === 'object') {
                if (value.status)
                    search = value.status;
            }

            return search;
        },
        sw: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
            let stringValue = ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale);

            return stringValue.slice(0, filterValue.length) === filterValue;
        },
        contains: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            // patch for diffrent type of column
            let search = this.filters.getVaule(value);

            let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale).trim();
            let stringValue = ObjectUtils.removeAccents(search.toString()).toLocaleLowerCase(filterLocale);
            return stringValue.indexOf(filterValue) !== -1;
        },
        in: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            // patch for diffrent type of column
            let search = this.filters.getVaule(value);

            let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale).trim();
            let stringValue = ObjectUtils.removeAccents(search.toString()).toLocaleLowerCase(filterLocale);
            return stringValue.indexOf(filterValue) !== -1;
        },

        nin: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            let search = this.filters.getVaule(value);

            let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
            let stringValue = ObjectUtils.removeAccents(search.toString()).toLocaleLowerCase(filterLocale);

            return stringValue.indexOf(filterValue) === -1;
        },

        ew: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }
            let search = this.filters.getVaule(value);

            let filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
            let stringValue = ObjectUtils.removeAccents(search.toString()).toLocaleLowerCase(filterLocale);

            return stringValue.indexOf(filterValue, stringValue.length - filterValue.length) !== -1;
        },

        eq: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }
            let search = this.filters.getVaule(value);

            if (value.getTime && filter.getTime)
                return value.getTime() === filter.getTime();
            else
                return ObjectUtils.removeAccents(search.toString()).toLocaleLowerCase(filterLocale) == ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
        },

        equals: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }
            let search = this.filters.getVaule(value);

            if (value.getTime && filter.getTime)
                return value.getTime() === filter.getTime();
            else
                return ObjectUtils.removeAccents(search.toString()).toLocaleLowerCase(filterLocale) == ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
        },

        neq: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return false;
            }

            if (value === undefined || value === null) {
                return true;
            }
            let search = this.filters.getVaule(value);

            if (value.getTime && filter.getTime)
                return value.getTime() !== filter.getTime();
            else
                // return ObjectUtils.removeAccents(value.toString()).toLocaleLowerCase(filterLocale) != ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
                return ObjectUtils.removeAccents(search.toString()).toLocaleLowerCase(filterLocale) !== ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(filterLocale);
        },

        // in: (value, filter: any[]): boolean => {
        //     if (filter === undefined || filter === null || filter.length === 0) {
        //         return true;
        //     }

        //     for (let i = 0; i < filter.length; i++) {
        //         if (ObjectUtils.equals(value, filter[i])) {
        //             return true;
        //         }
        //     }

        //     return false;
        // },

        between: (value, filter: any[]): boolean => {
            if (filter == null || filter[0] == null || filter[1] == null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime)
                return filter[0].getTime() <= value.getTime() && value.getTime() <= filter[1].getTime();
            else
            {

                return filter[0] <= value && value <= filter[1];
            }
        },
        ltegte: (value, filter: any[], filterLocale?): boolean => {
            if (filter == null || filter['start'] == null || filter['end'] == null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }
            const momentValue = moment(value, 'DD/MM/YYYY hh:mm:ss a');
            
            return filter['start'].isBefore(momentValue) && (filter['end'].isAfter(momentValue));
        },
        lt: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() < filter.getTime();
            else
                return value < filter;
        },

        lte: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() <= filter.getTime();
            else
                return value <= filter;
        },

        gt: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() > filter.getTime();
            else
                return value > filter;
        },

        gte: (value, filter, filterLocale?): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            if (value.getTime && filter.getTime)
                return value.getTime() >= filter.getTime();
            else
                return value >= filter;
        },

        is: (value, filter, filterLocale?): boolean => {
            return this.filters.eq(value, filter, filterLocale);
        },

        isNot: (value, filter, filterLocale?): boolean => {
            return this.filters.neq(value, filter, filterLocale);
        },

        before: (value, filter, filterLocale?): boolean => {
            return this.filters.lt(value, filter, filterLocale);
        },

        after: (value, filter, filterLocale?): boolean => {
            return this.filters.gt(value, filter, filterLocale);
        },

        dateIs: (value, filter): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            return value.toDateString() === filter.toDateString();
        },

        dateIsNot: (value, filter): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            return value.toDateString() !== filter.toDateString();
        },

        dateBefore: (value, filter): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            return value.getTime() < filter.getTime();
        },

        dateAfter: (value, filter): boolean => {
            if (filter === undefined || filter === null) {
                return true;
            }

            if (value === undefined || value === null) {
                return false;
            }

            return value.getTime() > filter.getTime();
        }

    }

    register(rule: string, fn: Function) {
        this.filters[rule] = fn;
    }
}